import React from 'react'
import { Grid, TextField } from '@mui/material'
import { FormField } from '../../../forms'
import { PrioritySelect } from '../../../selection'

export function CreateAlertInfo() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormField
          fullWidth
          label='Alert Summary'
          name='summary'
          required
          component={TextField}
        />
      </Grid>
      <Grid item xs={12}>
        <FormField
          fullWidth
          label='Priority'
          name='priorityID'
          fieldName='priorityID'
          required
          component={PrioritySelect}
        />
      </Grid>
      <Grid item xs={12}>
        <FormField
          fullWidth
          multiline
          rows={7}
          placeholder='Alert Details'
          name='details'
          component={TextField}
        />
      </Grid>
    </Grid>
  )
}
