import React from 'react'
import p from 'prop-types'
import { TextField, Grid } from '@mui/material'

import { FormContainer, FormField } from '../../forms'

export default function AlertDetailForm(props) {
  return (
    <FormContainer optionalLabels {...props}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormField
            fullWidth
            multiline
            rows={7}
            placeholder='Alert Details'
            name='details'
            component={TextField}
          />
        </Grid>
      </Grid>
    </FormContainer>
  )
}

AlertDetailForm.propTypes = {
  value: p.shape({
    details: p.string.isRequired,
  }).isRequired,

  errors: p.arrayOf(
    p.shape({
      field: p.oneOf(['details']).isRequired,
      message: p.string.isRequired,
    }),
  ),

  onChange: p.func.isRequired,
}
