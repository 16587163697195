import React, { useState } from 'react'
import { gql, useQuery, useMutation } from '@apollo/client'
import p from 'prop-types'

import { fieldErrors, nonFieldErrors } from '../../util/errutil'
import FormDialog from '../../dialogs/FormDialog'
import Spinner from '../../loading/components/Spinner'
import { GenericError } from '../../error-pages'
import AlertDetailForm from './AlertDetailForm'

const query = gql`
  query ($id: Int!) {
    alert(id: $id) {
      id
      alertID
      status
      summary
      details
    }
  }
`

const mutation = gql`
  mutation ($input: UpdateAlertDetailsInput!) {
    updateAlertDetails(input: $input) {
      details
      id
    }
  }
`

export default function EditAlertDetailDialog(props) {
  const [value, setValue] = useState(null)

  const { loading, error, data } = useQuery(query, {
    variables: { id: props.alertID },
    pollInterval: 0,
  })

  const [editRotation, editRotationStatus] = useMutation(mutation, {
    onCompleted: props.onClose,
  })

  if (loading && !data) return <Spinner />
  if (error) return <GenericError error={error.message} />

  return (
    <FormDialog
      title='Edit Rotation'
      errors={nonFieldErrors(editRotationStatus.error)}
      onClose={props.onClose}
      onSubmit={() =>
        editRotation({
          variables: {
            input: {
              id: props.alertID,
              ...value,
            },
          },
        })
      }
      form={
        <AlertDetailForm
          errors={fieldErrors(editRotationStatus.error)}
          disabled={editRotationStatus.loading}
          value={
            value || {
              details: data.alert.details,
            }
          }
          onChange={(value) => setValue(value)}
        />
      }
    />
  )
}

EditAlertDetailDialog.propTypes = {
  alertID: p.number.isRequired,
  onClose: p.func,
}
